<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.412"
    height="34.184"
    viewBox="0 0 34.412 34.184"
  >
    <path
      d="M188.843,81.36l-6.269-6.269a14.711,14.711,0,1,0-4.692,4.831l6.235,6.235s2.483,1.907,4.441-.051A3.49,3.49,0,0,0,188.843,81.36Zm-18.893-3.634a10.2,10.2,0,1,1,10.2-10.2A10.2,10.2,0,0,1,169.951,77.725Z"
      transform="translate(-155.234 -52.806)"
      fill="currentColor"
    />
  </svg>
</template>
